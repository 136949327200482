/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
// import ReactHtmlParser from 'react-html-parser';
import CurrencyInput from 'react-currency-input';
import { Collapse, notification, Select, Modal, Carousel } from 'antd';
import { IoIosArrowDown } from 'react-icons/io';
import {
  AiOutlineCheckCircle,
  AiOutlineWhatsApp,
  AiOutlineMail,
} from 'react-icons/ai';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Input from 'react-input-mask';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
// import Rodal from 'rodal';
import { ImAttachment } from 'react-icons/im';

// include styles
import 'rodal/lib/rodal.css';

import {
  Container,
  Banner,
  Porque,
  Funciona,
  LinkApps,
  Vantagem,
  Videos,
  Simulador,
  Perguntas,
  Interesse,
} from './styles';

// components
import Header from '../../components/HeaderGmadpay/Header';

// assets
import arrow from '../../assets/img/arrow.svg';

// api
import api from '../../services/api';
import cep from '../../services/cep';
import cnpj from '../../services/cnpj';
import ademail from '../../services/ademail';

export default function Home() {
  // const [modalOpen, setModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [videoInfo, setVideoInfo] = useState('');
  const [videoID, setVideoID] = useState('');
  const [dicas, setDicas] = useState();
  const [numerodeposts, setNumerodeposts] = useState(6);
  const [loading, setLoading] = useState(true);
  const [valordavenda, setValordavenda] = useState(0);
  const [parcelas, setParcelas] = useState();
  const [valorporparcela, setValorporparcela] = useState();
  const [valorLiquido, setValorLiquido] = useState();
  const [valorcomtaxa, setValorcomtaxa] = useState();
  const [valorparcelacomtaxa, setValorparcelacomtaxa] = useState();
  const [erroValor, setErroValor] = useState('');
  const [tipodaconta, setTipodaConta] = useState('Conta Corrente');
  const genExtra = () => <IoIosArrowDown />;
  const { Panel } = Collapse;
  const { Option } = Select;
  const [endereco, setEndereco] = useState({
    cep: '',
    rua: '',
    bairro: '',
    cidade: '',
    uf: '',
  });
  const [empresa, setEmpresa] = useState({
    cnpj: '',
    razaosocial: '',
    nomefantasia: '',
  });
  const [numCPF, setNumCPF] = useState();
  const [validoCPF, setValidoCPF] = useState();
  const [errocep, setErroCep] = useState();
  const [errorua, setErroRua] = useState();
  const [errobairro, setErrobairro] = useState();
  const [errocidade, setErroCidade] = useState();
  const [errouf, setErroUf] = useState();
  const [errocnpj, setErroCnpj] = useState();
  const [fileRG, setFileRG] = useState(undefined);
  const [erroFileRG, setErroFileRG] = useState();
  const [fileEndereco, setFileEndereco] = useState(undefined);
  const [erroFileEndereco, setErroFileEndereco] = useState();
  const [fileBancario, setFileBancario] = useState(undefined);
  const [erroFileBancario, setErroFileBancario] = useState();
  const [disabledButton, setDisabledButton] = useState(false);

  const videosRef = useRef();
  const carouselVideos = {
    adaptiveHeight: false,
    dots: false,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    autoplay: false,
    autoplaySpeed: 1000,
  };

  useEffect(() => {
    // setModalOpen(true);

    api
      .get(`posts?_embed&per_page=${numerodeposts}&categories=1612`)
      .then((response) => {
        setDicas(response.data);
        setLoading(false);
      });
  }, [numerodeposts]);

  let componentDidMount_super = CurrencyInput.prototype.componentDidMount;
  CurrencyInput.prototype.componentDidMount = function () {
    this.theInput.setSelectionRange_super = this.theInput.setSelectionRange;
    this.theInput.setSelectionRange = (start, end) => {
      if (document.activeElement === this.theInput) {
        this.theInput.setSelectionRange_super(start, end);
      }
    };
    componentDidMount_super.call(this, ...arguments);
  };

  function CalcularTaxas() {
    if (valordavenda === 0 || valordavenda === 'R$0,00') {
      setErroValor('Valor inválido!');
      return;
    }
    let valorcomponto = valordavenda.replace('.', '');
    let valorcomR = valorcomponto.replace(',', '.');
    let valor = Number(valorcomR.substr(2));

    let vpp = valor / Number(parcelas);
    console.log(vpp);

    const taxas = [
      2.4 / 100,
      4.57 / 100,
      5.48 / 100,
      6.27 / 100,
      7.18 / 100,
      7.97 / 100,
      8.75 / 100,
      9.5 / 100,
      10.3 / 100,
      10.65 / 100,
      11.8 / 100,
      12.2 / 100,
    ];

    if (parcelas === '1') {
      let valorliquido = valor - taxas[0] * valor;
      setValorLiquido(valorliquido);
      setValorporparcela(vpp);
      setValorcomtaxa(valor / (1 - taxas[0]));
      setValorparcelacomtaxa(valor / (1 - taxas[0]) / Number(parcelas));
    }
    if (parcelas === '2') {
      let valorliquido = valor - taxas[1] * valor;
      setValorLiquido(valorliquido);
      setValorporparcela(vpp);
      setValorcomtaxa(valor / (1 - taxas[1]));
      setValorparcelacomtaxa(valor / (1 - taxas[1]) / Number(parcelas));
    }
    if (parcelas === '3') {
      let valorliquido = valor - taxas[2] * valor;
      setValorLiquido(valorliquido);
      setValorporparcela(vpp);
      setValorcomtaxa(valor / (1 - taxas[2]));
      setValorparcelacomtaxa(valor / (1 - taxas[2]) / Number(parcelas));
    }
    if (parcelas === '4') {
      let valorliquido = valor - taxas[3] * valor;
      setValorLiquido(valorliquido);
      setValorporparcela(vpp);
      setValorcomtaxa(valor / (1 - taxas[3]));
      setValorparcelacomtaxa(valor / (1 - taxas[3]) / Number(parcelas));
    }
    if (parcelas === '5') {
      let valorliquido = valor - taxas[4] * valor;
      setValorLiquido(valorliquido);
      setValorporparcela(vpp);
      setValorcomtaxa(valor / (1 - taxas[4]));
      setValorparcelacomtaxa(valor / (1 - taxas[4]) / Number(parcelas));
    }
    if (parcelas === '6') {
      let valorliquido = valor - taxas[5] * valor;
      setValorLiquido(valorliquido);
      setValorporparcela(vpp);
      setValorcomtaxa(valor / (1 - taxas[5]));
      setValorparcelacomtaxa(valor / (1 - taxas[5]) / Number(parcelas));
    }
    if (parcelas === '7') {
      let valorliquido = valor - taxas[6] * valor;
      setValorLiquido(valorliquido);
      setValorporparcela(vpp);
      setValorcomtaxa(valor / (1 - taxas[6]));
      setValorparcelacomtaxa(valor / (1 - taxas[6]) / Number(parcelas));
    }
    if (parcelas === '8') {
      let valorliquido = valor - taxas[7] * valor;
      setValorLiquido(valorliquido);
      setValorporparcela(vpp);
      setValorcomtaxa(valor / (1 - taxas[7]));
      setValorparcelacomtaxa(valor / (1 - taxas[7]) / Number(parcelas));
    }
    if (parcelas === '9') {
      let valorliquido = valor - taxas[8] * valor;
      setValorLiquido(valorliquido);
      setValorporparcela(vpp);
      setValorcomtaxa(valor / (1 - taxas[8]));
      setValorparcelacomtaxa(valor / (1 - taxas[8]) / Number(parcelas));
    }
    if (parcelas === '10') {
      let valorliquido = valor - taxas[9] * valor;
      setValorLiquido(valorliquido);
      setValorporparcela(vpp);
      setValorcomtaxa(valor / (1 - taxas[9]));
      setValorparcelacomtaxa(valor / (1 - taxas[9]) / Number(parcelas));
    }
    if (parcelas === '11') {
      let valorliquido = valor - taxas[10] * valor;
      setValorLiquido(valorliquido);
      setValorporparcela(vpp);
      setValorcomtaxa(valor / (1 - taxas[10]));
      setValorparcelacomtaxa(valor / (1 - taxas[10]) / Number(parcelas));
    }
    if (parcelas === '12') {
      let valorliquido = valor - taxas[11] * valor;
      setValorLiquido(valorliquido);
      setValorporparcela(vpp);
      setValorcomtaxa(valor / (1 - taxas[11]));
      setValorparcelacomtaxa(valor / (1 - taxas[11]) / Number(parcelas));
    }
  }

  function pesquisacep(valor) {
    setEndereco({ cep: valor });
    var numcep = valor.replace(/\D/g, '');

    if (numcep !== '') {
      var validacep = /^[0-9]{8}$/;

      if (validacep.test(numcep)) {
        document.getElementById('rua').value = '...';
        document.getElementById('bairro').value = '...';
        document.getElementById('cidade').value = '...';
        document.getElementById('uf').value = '...';

        cep.get(`/cep.json?code=${numcep}`).then((response) => {
          if (response.data.status === 200) {
            setEndereco({
              cep: response.data.code,
              rua: response.data.address,
              bairro: response.data.district,
              cidade: response.data.city,
              uf: response.data.state,
            });
            setErroCep();
            setErroRua();
            setErrobairro();
            setErroCidade();
            setErroUf();
          } else if (response.data.status === 404) {
            setErroCep('CEP não encontrado');
            setEndereco({
              cep: valor,
              rua: '',
              bairro: '',
              cidade: '',
              uf: '',
            });
          }
        });
      }
    }
  }
  const [formButton, setFormButton] = useState('FAZER SOLICITAÇÃO');

  const lojas = [
    'GMAD ABC',
    'GMAD Americana',
    'GMAD BM Madeiras',
    'GMAD Bridi Madeiras',
    'GMAD Buogini',
    'GMAD Campinas',
    'GMAD Campo Grande',
    'GMAD Campo Largo',
    'GMAD Canoas',
    'GMAD Cariri',
    'GMAD Casa da Madeira Anápolis',
    'GMAD Casa da Madeira - Brasília',
    'GMAD Casa do Marceneiro - GO T63',
    'GMAD Casa da Madeira Taguatinga',
    'GMAD Casa do Marceneiro - GO Coimbra',
    'GMAD Casa do Marceneiro - Palmas',
    'GMAD Casa do MDF',
    'GMAD Centro do Alumínio',
    'GMAD Centro Oeste',
    'GMAD Chapecomp',
    'GMAD Compin',
    'GMAD Complond',
    'GMAD Compmag',
    'GMAD Feira de Santana',
    'GMAD Flaviense Campo Grande',
    'GMAD Flaviense Curicica',
    'GMAD Flaviense Duque - (Filial 2)',
    'GMAD Flaviense Duque',
    'GMAD Flaviense Nova Iguaçu',
    'GMAD Flaviense São Gonçalo',
    'GMAD Flaviense Zona Norte',
    'GMAD Flaviense Maricá',
    'GMAD Fortaleza',
    'GMAD Guarapuava',
    'GMAD Jamad Cabedelo',
    'GMAD Jamad Natal',
    'GMAD JK Madeiras',
    'GMAD JKV Madeiras',
    'GMAD JR Madeiras',
    'GMAD JR Madeiras - Volta Redonda - RJ',
    'GMAD Lojão do Marceneiro - Porto Velho - RO',
    'GMAD Lojão do Marceneiro - Matriz',
    'GMAD Lojão do Marceneiro Ji Paraná',
    'GMAD Lojão do Marceneiro Rio Branco',
    'GMAD Madcaju',
    'GMAD Madcentro',
    'GMAD Madcentro Itu',
    'GMAD Madcentro JundiaÍ',
    'GMAD Madcompen',
    'GMAD Madecari',
    'GMAD Madecenter Caruaru',
    'GMAD Madecenter Centro 2',
    'GMAD Madecenter Imbiribeira',
    'GMAD Madecenter Matriz',
    'GMAD Madeireira Palowa BR040',
    'GMAD Madeireira Palowa Ipatinga',
    'GMAD Madeireira Palowa Tamoios',
    'GMAD Madeireira Palowa Tupis',
    'GMAD Madplex',
    'GMAD Madville',
    'GMAD MF Madeiras',
    'Gmad Montes Claros',
    'GMAD Riga Madeiras - Vila Ré',
    'GMAD Pinheirinho',
    'GMAD Placa Foz',
    'GMAD Placa Sul',
    'GMAD Placas Uberaba',
    'GMAD Placavel',
    'GMAD Ponto do Marceneiro',
    'GMAD Pouso Alegre',
    'GMAD Ribeirão',
    'GMAD Riga Madeiras - Itaquaquecetuba',
    'GMAD Rio Preto',
    'GMAD RV Madeiras',
    'GMAD Salvador',
    'GMAD Segmar',
    'GMAD Taboão Madeiras',
    'GMAD Vitória da Conquista',
    'GMAD Santo Antônio de Jesus',
    'GMAD Flaviense Juiz de Fora',
    'GMAD Flaviense Itaguaí',
  ];
  const [loja, setLoja] = useState('');
  const formik = useFormik({
    initialValues: {
      loja: loja,
      vendedor: '',
      cnpj: empresa.cnpj,
      incricaoestadual: '',
      razaosocial: empresa.razaosocial,
      nomefantasia: empresa.nomefantasia,
      nome: '',
      cpf: numCPF,
      nascimento: '',
      nomedamae: '',
      celular: '',
      telefonefixo: '',
      email1: '',
      email2: '',
      cep: endereco.cep,
      endereco: endereco.rua,
      numero: '',
      bairro: endereco.bairro,
      cidade: endereco.cidade,
      uf: endereco.uf,
      banco: '',
      agencia: '',
      conta: '',
      tipodaconta: '',
    },

    validationSchema: Yup.object({
      // loja: Yup.string().required('Campo obrigatório'),
      nome: Yup.string().required('Campo obrigatório'),
      nascimento: Yup.string().required('Campo obrigatório'),
      nomedamae: Yup.string().required('Campo obrigatório'),
      celular: Yup.string().required('Campo obrigatório'),
      email1: Yup.string()
        .email('Informe um e-mail válido')
        .required('Campo obrigatório'),
      numero: Yup.string().required('Campo obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
            <h4>Dados para cadastro</h4>
            <p>Loja GMAD em que realiza as compras: ${loja}</p>
            <p>Vendedor: ${values.vendedor}</p>
            <h4>Dados da marcenaria</h4>
            <p>CNPJ: ${empresa.cnpj}</p>
            <p>Incrição estadual: ${values.incricaoestadual}</p>
            <p>Razão social: ${empresa.razaosocial}</p>
            <p>Nome fantasia da marcenaria: ${empresa.nomefantasia}</p>
            <h4>Dados do proprietário</h4>
            <p>Nome completo: ${values.nome}</p>
            <p>CPF: ${numCPF}</p>
            <p>Data de nascimento: ${values.nascimento}</p>
            <p>Nome da mãe: ${values.nomedamae}</p>
            <p>Celular: ${values.celular}</p>
            <p>Telefone fixo: ${values.telefonefixo}</p>
            <p>E-mail 1: ${values.email1}</p>
            <p>E-mail 2: ${values.email2}</p>
            <h4>Endereço da marcenaria</h4>
            <p>CEP: ${endereco.cep}</p>
            <p>Endereço: ${endereco.rua}</p>
            <p>Número: ${values.numero}</p>
            <p>Bairro: ${endereco.bairro}</p>
            <p>Cidade: ${endereco.cidade}</p>
            <p>Estado: ${endereco.uf}</p>
            <h4>Dados bancários</h4>
            <p>Banco: ${values.banco}</p>
            <p>Agência: ${values.agencia}</p>
            <p>Conta: ${values.conta}</p>
            <p>Tipo da conta: ${tipodaconta}</p>
            <p>Documentos necessários em anexo</p>
          `;

      const attachRG = fileRG;
      const attachEndereco = fileEndereco;
      const attachBancario = fileBancario;
      const mail = new FormData();

      mail.append('to', 'contato.gmais@gmad.com.br'); //contato.gmais@gmad.com.br
      mail.append('from', values.email1);
      mail.append('as', values.nome);
      // mail.append('cc', JSON.stringify(['victormonteiro@agenciaade.com.br']));
      mail.append('subject', 'Novo interessado no GMADPay');
      mail.append('message', body);
      if (attachRG) mail.append('attach', attachRG);
      if (attachEndereco) mail.append('attach', attachEndereco);
      if (attachBancario) mail.append('attach', attachBancario);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      try {
        // Sucesso ao enviarsetDisabledButton(true);
        // setDisabledButton(true);
        if (
          endereco.cep !== '' &&
          endereco.rua !== '' &&
          endereco.bairro !== '' &&
          endereco.cidade !== '' &&
          endereco.uf !== '' &&
          validoCPF !== false &&
          errocnpj !== 'Informe um CNPJ válido' &&
          fileRG !== undefined &&
          fileEndereco !== undefined &&
          fileBancario !== undefined &&
          loja !== ''
        ) {
          await axios
            .post('https://api.agenciaade.com.br/email-attach', mail, config)
            .then((response) => {
              console.log(response);
            });

          notification.success({
            message: 'Contato enviado com sucesso! 🚀',
            placement: 'bottomLeft',
            className: 'custom-notification',
            style: {
              width: 500,
              height: 120,
            },
          });
          setFormButton('CADASTRO ENVIADO!');

          setEndereco({
            cep: '',
            rua: '',
            bairro: '',
            cidade: '',
            uf: '',
          });
          setEmpresa({
            cnpj: '',
            razaosocial: '',
            nomefantasia: '',
          });
          setNumCPF('');
          setValidoCPF();
          setTipodaConta('Conta Corrente');
          setFileRG(undefined);
          setFileEndereco(undefined);
          setFileBancario(undefined);
          setErroFileRG();
          setErroFileEndereco();
          setErroFileBancario();
          setDisabledButton(false);
          setLoja('');
          resetForm();
        } else {
          notification.error({
            message: 'Não foi possivel enviar o contato! 😔',
            description: 'Verfique os campos e tente novamente',
            placement: 'bottomLeft',
            className: 'custom-notification',
            style: {
              width: 400,
              height: 100,
            },
            duration: 50000,
          });
        }
      } catch (error) {
        // Erro ao enviar
        console.log(error);
        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'topRight',
          className: 'custom-notification',
          style: {
            width: 600,
            height: 300,
          },
        });
      }
    },
  });

  function pesquisaCNPJ(valor) {
    setEmpresa({ cnpj: valor });
    const valorCNPJ = valor.replace(/\D/g, '');

    if (valorCNPJ.length === 14) {
      document.getElementById('razaosocial').value = '...';
      document.getElementById('nomefantasia').value = '...';

      cnpj
        .get(`${valorCNPJ}`, {
          headers: {
            authorization:
              'c24f1e85-4179-4786-8af5-a2c2d8351d3a-9e4cb22d-0af4-421b-ba9b-30e0bce00fb0',
          },
        })
        .then((response) => {
          console.log(response.data);
          setEmpresa({
            cnpj: valor,
            razaosocial: response.data.name,
            nomefantasia: response.data.alias,
          });
          setErroCnpj(null);

          if (response.data.alias === null) {
            setEmpresa({
              cnpj: valor,
              razaosocial: response.data.name,
              nomefantasia: '',
            });
          }
        })
        .catch((err) => {
          setErroCnpj('Informe um CNPJ válido');
          setEmpresa({
            cnpj: valor,
            razaosocial: '',
            nomefantasia: '',
          });
        });
    }
  }

  function TestaCPF(valor) {
    var Soma;
    var Resto;
    Soma = 0;
    setNumCPF(valor);
    const valorCPF = valor.replace(/\D/g, '');
    if (valorCPF === '00000000000') return setValidoCPF(false);

    for (let i = 1; i <= 9; i += 1)
      Soma = Soma + parseInt(valorCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(valorCPF.substring(9, 10)))
      return setValidoCPF(false);

    Soma = 0;
    for (let i = 1; i <= 10; i += 1)
      Soma = Soma + parseInt(valorCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(valorCPF.substring(10, 11)))
      return setValidoCPF(false);
    return setValidoCPF(true);
  }

  function handleSelectedOption(value) {
    setParcelas(value);
  }

  function handleSubmit() {
    if (endereco.cep === '') {
      setErroCep('Campo Obrigatório');
    }

    if (endereco.rua === '') {
      setErroRua('Campo Obrigatório');
    }

    if (endereco.bairro === '') {
      setErrobairro('Campo Obrigatório');
    }

    if (endereco.cidade === '') {
      setErroCidade('Campo Obrigatório');
    }

    if (endereco.uf === '') {
      setErroUf('Campo Obrigatório');
    }

    if (fileRG === undefined) {
      setErroFileRG('Campo Obrigatório');
    }

    if (fileEndereco === undefined) {
      setErroFileEndereco('Campo Obrigatório');
    }

    if (fileBancario === undefined) {
      setErroFileBancario('Campo Obrigatório');
    }
  }

  return (
    <>
      <Helmet>
        <title>GMAD | App GMADpay</title>
        <meta
          name="description"
          content="GMADpay faz parte das iniciativas GMAD para apoiar ainda mais sua marcenaria. O aplicativo é uma delas. Uma opção prática e no seu bolso para controle dos recebimentos."
        />
        {/* <meta
          name="keywords"
          content="marca, campanha, site, impresso, facebook, identidade visual, logo"
        /> */}
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>
      <Header />
      {/* <Rodal
        customStyles={{
          background: 'none',
          padding: '2rem',
          height: '30rem',
          width: '40rem',
        }}
        visible={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <a style={{ background: 'none' }} href="/#simuladordetaxas">
          <img src={require('../../assets/img/popup.png')} alt="" />
        </a>
      </Rodal> */}
      <Container>
        <Banner>
          <div className="texto">
            <h2>
              Com as melhores taxas do mercado e ainda, controle tudo{' '}
              <strong>pelo seu celular</strong>!
            </h2>
            <p>
              <strong>G+</strong> são iniciativas <strong>GMAD</strong> para
              apoiar ainda mais sua marcenaria. O GMADPay é uma delas. Tenha em
              mãos toda a praticidade e segurança para realizar e gerir suas
              vendas, com recebimento do serviço à vista.
            </p>
          </div>
          <div className="imagem">
            <img
              src={require('../../assets/img/hand-app.png')}
              alt="Maquininha G+"
            />
          </div>
        </Banner>

        <Porque id="porqueaderir">
          <div className="porque">
            <h4>
              Faça a gestão e acompanhamento das suas vendas{' '}
              <strong>pelo seu celular</strong> ou através do{' '}
              <strong>Portal GMADPay</strong>
            </h4>
            <div className="bg-img">
              <img
                src={require('../../assets/img/happy-man.png')}
                alt="Homem feliz trabalhando"
              />
            </div>
          </div>
          <img
            id="detail"
            src={require('../../assets/img/mais-detail.png')}
            alt="Mais"
          />
        </Porque>

        <Funciona>
          <h4 id="comofunciona">
            Como me cadastrar no <strong>GMADPay</strong>?
          </h4>
          <p>
            Se estiver de acordo, precisaremos da cópia ou foto dos seguintes
            documentos:
          </p>
          <div className="cards">
            <div>
              <p>Cartão CNPJ (se o cadastro for no CNPJ)</p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>
                Foto RG e CPF ou CNH do proprietário (se o cadastro for no CNPJ,
                os documentos devem ser de quem aparece como principal sócio na
                Receita Federal)
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>
                Comprovante de endereço do CNPJ (ou do CPF quando cadastrado em
                pessoa física)
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>
                Comprovante bancário, pode ser foto do cartão da conta
                corrente/poupança que estará vinculada ao GMADPay. Pode ser
                também uma folha de cheque ou foto do cabeçalho do extrato
                bancário (lembre-se de que o titular da conta bancária deve ser
                o mesmo que o cadastro do GMADPay)
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>

              <p>
                A loja GMAD irá fazer o seu cadastro e em até 4 dias úteis seu
                cadastro estará liberado!
              </p>
              <div className="bottom">
                <img
                  src={require('../../assets/img/bottom-arrow.svg')}
                  alt="Arrow"
                />
              </div>
            </div>
            <div>
              <p>
                Cópia de uma nota fiscal da loja GMAD para este marceneiro,
                comprovando a atividade
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>Celular com DDD</p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>E-mail</p>
            </div>

            <div>
              <p>
                Para acessar, utilize o e-mail informado no cadastro e a senha é
                o cpf do proprietário (somente números).
              </p>
              <div className="bottom">
                <img
                  src={require('../../assets/img/bottom-arrow.svg')}
                  alt="Arrow"
                />
              </div>
            </div>
            <div>
              <p>
                A partir daí você já pode fazer suas vendas pelo app GMADPay e
                acompanhar suas transações tanto pelo celular quanto pelo portal
                no seu computador!
              </p>
              <div className="bottom">
                <img
                  src={require('../../assets/img/bottom-arrow.svg')}
                  alt="Arrow"
                />
              </div>
            </div>
            <div>
              <p>
                E caso você precise de mais acessos, mesmo pode criá-los pelo
                portal! É muito fácil
              </p>
            </div>
          </div>

          {/* Para screen 680px */}

          <div className="cardsresizedfor2">
            <div>
              <p>Cartão CNPJ (se o cadastro for no CNPJ)</p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>
                Foto RG e CPF ou CNH do proprietário (se o cadastro for no CNPJ,
                os documentos devem ser de quem aparece como principal sócio na
                Receita Federal)
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>

            <div>
              <p>
                Comprovante de endereço do CNPJ (ou do CPF quando cadastrado em
                pessoa física)
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>
                Comprovante bancário, pode ser foto do cartão da conta
                corrente/poupança que estará vinculada ao GMADPay. Pode ser
                também uma folha de cheque ou foto do cabeçalho do extrato
                bancário (lembre-se de que o titular da conta bancária deve ser
                o mesmo que o cadastro do GMADPay)
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>

            <div>
              <p>E-mail</p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>Celular com DDD</p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>

            <div>
              <p>
                Cópia de uma nota fiscal da loja GMAD para este marceneiro,
                comprovando a atividade
              </p>
            </div>
            <div>
              <p>
                A loja GMAD irá fazer o seu cadastro e no dia seguinte seu
                acesso estará liberado!
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>

            <div>
              <p>
                Para acessar, utilize o e-mail informado no cadastro e a senha é
                o cpf do proprietário (somente números).
              </p>
            </div>
            <div>
              <p>
                A partir daí você já pode fazer suas vendas pelo app GMADPay e
                acompanhar suas transações tanto pelo celular quanto pelo portal
                no seu computador!
              </p>
              <div className="bottom">
                <img
                  src={require('../../assets/img/bottom-arrow.svg')}
                  alt="Arrow"
                />
              </div>
            </div>

            <div>
              <p>
                E caso você precise de mais acessos, mesmo pode cria-los pelo
                portal! É muito fácil!
              </p>
              <div className="bottom">
                <img
                  src={require('../../assets/img/bottom-arrow.svg')}
                  alt="Arrow"
                />
              </div>
            </div>
          </div>
          <p className="small-warning">
            * informe-se sobre as condições de uso e taxas do app GMADPay
          </p>

          <img
            id="detail"
            src={require('../../assets/img/mais-laranja.png')}
            alt="Mais"
          />
        </Funciona>

        <LinkApps>
          <div className="texto" id="vantagens">
            <h2>
              O <strong>próprio App simula as taxas</strong> na hora da venda
            </h2>
            <h4>Muito mais opções na sua mão!</h4>
            <p>
              A melhor ferramenta para tomada de decisão na hora que você está
              com seu cliente
            </p>

            <div className="app-links">
              <a
                href="https://apps.apple.com/br/app/gmad-pay/id1544762799"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/img/app-store.svg')}
                  alt="Link App Store"
                />
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=com.acanto.gmad"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/img/play-store.svg')}
                  alt="Link Play Store"
                />
              </a>
            </div>
          </div>
          <div className="imagem">
            <img
              src={require('../../assets/img/celular-gmad-pay.png')}
              alt="Celular App Gmadpay"
            />
          </div>
        </LinkApps>

        <Vantagem>
          <img
            src={require('../../assets/img/celular-man.jpg')}
            alt="Homem sorrindo mexendo no celular"
          />
          <div className="texto">
            <h4>
              Solução pensada <strong>no público</strong>
            </h4>
            <p>
              Hora e dia que quiser, sem limite de transações, no momento mais
              interessante. Se você não quiser utilizar não tem problema, sem
              compromisso, sem fidelidade.
            </p>
          </div>
        </Vantagem>

        <Videos>
          <button type="button" onClick={() => videosRef.current.prev()}>
            <FaChevronLeft size={32} />
          </button>
          <div className="carousel-container">
            <Carousel ref={videosRef} {...carouselVideos} effect="fade">
              <div className="container-iframe">
                <iframe
                  width="560"
                  height="315"
                  // src="https://www.youtube.com/embed/0pN-5WG76Pk"
                  src="https://www.youtube.com/embed/owrwO8PL4ts"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Carousel>
          </div>
          <button type="button" onClick={() => videosRef.current.next()}>
            <FaChevronRight size={32} />
          </button>
        </Videos>

        <Simulador style={{ display: 'none' }} id="simuladordetaxas">
          <h4>
            Simulador de <strong>taxas</strong>
          </h4>
          <div className="firstsbox">
            <div>
              <div className="inputfield">
                <label>Valor da venda:</label>
                <CurrencyInput
                  name="valor"
                  value={valordavenda}
                  onChange={(maskedvalue) => {
                    setValordavenda(maskedvalue);
                    setErroValor();
                  }}
                  prefix="R$"
                  decimalSeparator=","
                  thousandSeparator="."
                />
              </div>
              {erroValor && <span>{erroValor}</span>}
            </div>

            <div>
              <div className="inputfield">
                <label>Número de parcelas:</label>
                <Select value={parcelas} onChange={handleSelectedOption}>
                  {/* <Option value="Débito">Débito</Option> */}
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                  <Option value="6">6</Option>
                  <Option value="7">7</Option>
                  <Option value="8">8</Option>
                  <Option value="9">9</Option>
                  <Option value="10">10</Option>
                  <Option value="11">11</Option>
                  <Option value="12">12</Option>
                </Select>
              </div>
            </div>
            <button type="button" onClick={CalcularTaxas}>
              CALCULAR
            </button>
          </div>

          <fieldset id="resultados">
            <div className="firsthalf">
              <div>
                <label>Valor por parcela para o consumidor:</label>
                <div className="resultado">
                  <CurrencyInput
                    value={valorporparcela}
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                    disabled
                  />
                </div>
                <label>Valor líquido total da venda:</label>

                <div className="resultado">
                  <CurrencyInput
                    value={valorLiquido}
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                    disabled
                  />
                </div>
              </div>
              <div>
                <div className="simuladorinfos">
                  <p>
                    Para cada transação você poderá escolher entre retirar 30%
                    do valor líquido em materiais na loja GMAD ou então o valor
                    total!
                  </p>
                  <p>
                    Basta selecionar a opção Realizar divisão da venda no
                    aplicativo.
                  </p>
                  <p>
                    Quando este botão estiver ativado, será transferido 30% para
                    a loja GMAD.
                  </p>
                  <p>
                    Quando este botão estiver inativo, será transferido 100%
                    para a loja GMAD e então você poderá retirar o valor total
                    em materiais!
                  </p>
                </div>
              </div>
            </div>
            <div className="secondhalf">
              <p className="title">
                Caso queira incluir as taxas no valor da venda:
              </p>
              <div>
                <div>
                  <label>Valor total a cobrar:</label>
                  <div className="resultado">
                    <CurrencyInput
                      value={valorcomtaxa}
                      prefix="R$"
                      decimalSeparator=","
                      thousandSeparator="."
                      disabled
                    />
                  </div>
                </div>
                <div>
                  <label>
                    Valor por parcela para o Consumidor, com as taxas
                  </label>
                  <div className="resultado">
                    <CurrencyInput
                      value={valorparcelacomtaxa}
                      prefix="R$"
                      decimalSeparator=","
                      thousandSeparator="."
                      disabled
                    />
                  </div>
                </div>
              </div>
              {/* <p>
                *taxas por bandeira: 0,90% débito; 1,70% crédito (recebimento
                D+1); 2,10% parcelado de 2 a 18 vezes
              </p>
              <p>**taxa de antecipação do parcelado: 1,20% ao mês</p> */}
            </div>
          </fieldset>
        </Simulador>

        <Perguntas id="perguntasfrequentes">
          <h4>
            FAQ <strong>(Perguntas Frequentes)</strong>
          </h4>
          <Collapse>
            <Panel
              header="COMO ME CADASTRAR?"
              key="1"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Entre em contato com seu vendedor GMAD, providencie o envio dos
                documentos necessários e no dia seguinte o seu acesso já estará
                liberado.
              </p>

              <p>Documentos necessários:</p>
              <p> - Cartão CNPJ (quando o cadastro for em pessoa jurídica)</p>
              <p> - RG/CPF ou CNH do sócio principal</p>
              <p>
                - Comprovante de endereço (se o cadastro for em cnpj, o
                comprovante deve ser da mesma razão social)
              </p>
              <p>
                - Comprovante bancário. Pode ser uma foto do cartão ou da olha
                de cheque vinculada a esta conta. Pode ser também uma foto ou do
                cabeçalho do extrato bancário (a conta deve ser sempre de mesma
                titularidade ao cadastro. Se for cadastrado e cnpj a conta deve
                ser jurídica desta razão social. Se for cadastrado em pessoa
                física, a conta deve ser deste mesmo titular)
              </p>
              <p> - informar seu e-mail</p>
              <p> - informar celular para contato</p>
              <p>
                - uma nota fiscal da loja GMAD faturada para a sua marcenaria. O
                seu vendedor GMAD poderá providenciar este documento para você!
              </p>
            </Panel>
            <Panel
              header="MEU DINHEIRO FICA PRESO COM VOCÊS?"
              key="2"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Após dois dias (úteis) seguintes à transação, você receberá os
                valores da sua venda definidos na operação realizada. Você pode
                optar em receber 70% na sua conta bancária e 30% para retirar em
                materiais na sua loja GMAD ou retirar 100% em materiais na sua
                loja GMAD.
              </p>
            </Panel>
            <Panel
              header="EU PRECISO TER CONTA EM BANCO?"
              key="3"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Sim. Cadastro em pessoa física, a conta bancária precisa ser
                física naquela titularidade e cadastro em pessoa jurídica, a
                conta precisa ser jurídica, vinculada aquele CNPJ.
              </p>
            </Panel>
            <Panel
              header="A CONTA BANCÁRIA PARA CRÉDITO DO VALOR PODE SER POUPANÇA?"
              key="4"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Após a finalização do seu cadastro com a GMAD, você será
                orientado a ativar a conta digital Pagbank para a transferência
                dos valores, seja para conta corrente ou conta poupança.
              </p>
            </Panel>
            <Panel
              header="QUAL O MÁXIMO DE PARCELAMENTO QUE PODE SER FEITO?"
              key="5"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                O parcelamento permitido na maquininha é em até 18x e em todas
                as formas de parcelamento, o marceneiro e loja recebem o valor
                no dia seguinte à transação. Contudo entre 13 e 18 parcelas,
                somente com os cartões do Banco do Brasil e Caixa Econômica
                mediante aprovação do portador do cartão junto ao banco emissor.
                Alguns cartões do banco Bradesco também são aceitos o
                parcelamento máximo, mas também necessitam da solicitação de
                aprovação do portador do cartão junto ao banco emissor.
              </p>
            </Panel>
            <Panel
              header="POSSO TER MAIS DE UM USUÁRIO PARA ACESSO AO GMADPay?"
              key="6"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Sim, após receber os dados do seu acesso, você pode criar
                quantos usuários desejar. Todos estarão vinculados à sua
                marcenaria e à loja GMAD que efetuou o seu cadastro.
              </p>
            </Panel>
            <Panel
              header="QUAL A DIFERENÇA ENTRE O PARCELAMENTO VENDEDOR E COMPRADOR?"
              key="7"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Na opção VENDEDOR, as taxas serão deduzidas do valor simulado.
                <br />
                Já na opção COMPRADOR, o valor simulado se torna o valor líquido
                da transação, e o aplicativo irá informar o valor bruto da venda
                para cada tipo de parcelamento.
              </p>
            </Panel>
            <Panel
              header="TEM DIFERENÇA NAS TAXAS DA VENDA PRESENCIAL E NO LINK DE PAGAMENTO?"
              key="8"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Não, com o aplicativo GMADPay você pode vender em ambas as
                modalidades com as mesmas condições e taxas de recebimento! A
                diferença é que você pode gerar um link de pagamento para o seu
                cliente sem sair da sua marcenaria, e fazer todo o
                acompanhamento da venda na palma da sua mão, ou melhor, no seu
                aplicativo GMADPay!
              </p>
            </Panel>
          </Collapse>
          <div className="duvidas">
            <div className="d1">
              <h4>
                Mais <strong>Dúvidas?</strong>
              </h4>
            </div>

            <div className="d2">
              <div>
                <p>
                  Conte sempre com seu <strong>vendedor GMAD</strong> por whats,
                  na loja ou até na sua marcenaria.
                </p>
                <p>
                  Além de contar com o seu vendedor GMAD, a Central de
                  Atendimento G+ estará à disposição para te auxiliar no que
                  precisar! De segunda à sexta das 8h às 12h e das 13h30 às 18h.
                </p>
                <br />
              </div>
              <div>
                <p>
                  Por e-mail no{' '}
                  <a
                    href="mailto:contato.gmais@gmad.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiOutlineMail />
                    contato.gmais@gmad.com.br
                  </a>{' '}
                  <a
                    href="mailto:atendimento1@gmad.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiOutlineMail />
                    atendimento1@gmad.com.br
                  </a>
                </p>
                <p>
                  ou pelo whatsapp no{' '}
                  <a
                    href="https://wa.me/5542991135586"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiOutlineWhatsApp />
                    (42) 99113-5586
                  </a>
                  <a
                    href="https://wa.me/554284094524"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiOutlineWhatsApp />
                    (42) 8409-4524
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Perguntas>

        <Interesse id="tenhointeresse">
          <h4>
            Tenho Interesse no <strong>GMADPay</strong>
          </h4>
          <form onSubmit={formik.handleSubmit}>
            <fieldset>
              <p className="title">
                Dados para <strong>cadastro</strong>
              </p>
              <div className="cadastro">
                <div>
                  <label>
                    Em qual loja GMAD você costuma fazer suas compras? *
                  </label>
                  <select
                    id="lojaa"
                    name="lojaa"
                    value={loja}
                    onChange={(e) => setLoja(e.target.value)}
                    onBlur={formik.handleBlur}
                    className={`select-loja ${
                      loja === '' && 'redborder anexar-btn'
                    }`}
                  >
                    <option value="" disabled={true} hidden={true}>
                      Selecione uma loja GMAD
                    </option>
                    {lojas.map((lol) => {
                      return (
                        <option value={lol} key={lol}>
                          {lol}
                        </option>
                      );
                    })}
                  </select>
                  {/* <input
                    type="text"
                    name="loja"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.loja}
                    className={
                      formik.touched.loja && formik.errors.loja
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.loja && formik.errors.loja ? (
                    <span>{formik.errors.loja}</span>
                  ) : null} */}
                </div>
                <div>
                  <label>Com qual vendedor?</label>
                  <input
                    type="text"
                    name="vendedor"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.vendedor}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <p className="title">
                Dados da <strong>marcenaria</strong>
              </p>
              <div className="marcenaria">
                <div>
                  <label>CNPJ</label>
                  <Input
                    type="tel"
                    mask="99.999.999/9999-99"
                    maskChar={null}
                    name="cnpj"
                    value={empresa.cnpj}
                    onChange={(e) => {
                      pesquisaCNPJ(e.target.value);
                    }}
                    className={errocnpj ? 'redborder' : ''}
                  />
                  {errocnpj ? <span>{errocnpj}</span> : null}
                </div>
                <div>
                  <label>Inscrição estadual</label>
                  <input
                    type="text"
                    name="incricaoestadual"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.incricaoestadual}
                  />
                </div>
                <div>
                  <label>Razão social</label>
                  <input
                    id="razaosocial"
                    type="text"
                    name="razaosocial"
                    value={empresa.razaosocial}
                    onChange={(e) =>
                      setEmpresa({
                        cnpj: empresa.cnpj,
                        razaosocial: e.target.value,
                        nomefantasia: empresa.nomefantasia,
                      })
                    }
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div>
                  <label>Nome fantasia da marcenaria</label>
                  <input
                    id="nomefantasia"
                    type="text"
                    name="nomefantasia"
                    onChange={(e) =>
                      setEmpresa({
                        cnpj: empresa.cnpj,
                        razaosocial: empresa.razaosocial,
                        nomefantasia: e.target.value,
                      })
                    }
                    onBlur={formik.handleBlur}
                    value={empresa.nomefantasia}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <p className="title">
                Dados do <strong>proprietário</strong>
              </p>
              <div className="proprietario1">
                <div>
                  <label>Nome completo (sem abreviações)*</label>
                  <input
                    type="text"
                    name="nome"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nome}
                    className={
                      formik.touched.nome && formik.errors.nome
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.nome && formik.errors.nome ? (
                    <span>{formik.errors.nome}</span>
                  ) : null}
                </div>
                <div>
                  <label>CPF*</label>
                  <Input
                    type="tel"
                    mask="999.999.999-99"
                    maskChar={null}
                    name="cpf"
                    onChange={(e) => TestaCPF(e.target.value)}
                    onBlur={formik.handleBlur}
                    value={numCPF}
                    className={validoCPF === false ? 'redborder' : ''}
                  />
                  {validoCPF === true && (
                    <span style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      CPF válido <AiOutlineCheckCircle />
                    </span>
                  )}
                  {validoCPF === false && <span>Informe um CPF válido</span>}
                </div>
                <div>
                  <label>Data de nascimento*</label>
                  <Input
                    type="tel"
                    mask="99/99/9999"
                    maskChar={null}
                    name="nascimento"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nascimento}
                    className={
                      formik.touched.nascimento && formik.errors.nascimento
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.nascimento && formik.errors.nascimento ? (
                    <span>{formik.errors.nascimento}</span>
                  ) : null}
                </div>
                <div>
                  <label>Nome da mãe*</label>
                  <input
                    type="text"
                    name="nomedamae"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nomedamae}
                    className={
                      formik.touched.nomedamae && formik.errors.nomedamae
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.nomedamae && formik.errors.nomedamae ? (
                    <span>{formik.errors.nomedamae}</span>
                  ) : null}
                </div>
              </div>
              <div className="proprietario2">
                <div>
                  <label>Celular*</label>
                  <Input
                    type="tel"
                    mask="(99) 99999-9999"
                    maskChar={null}
                    name="celular"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.celular}
                    className={
                      formik.touched.celular && formik.errors.celular
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.celular && formik.errors.celular ? (
                    <span>{formik.errors.celular}</span>
                  ) : null}
                </div>
                <div>
                  <label>Telefone fixo</label>
                  <Input
                    type="tel"
                    mask="(99) 9999-9999"
                    maskChar={null}
                    name="telefonefixo"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.telefonefixo}
                    className={
                      formik.touched.telefonefixo && formik.errors.telefonefixo
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.telefonefixo && formik.errors.telefonefixo ? (
                    <span>{formik.errors.telefonefixo}</span>
                  ) : null}
                </div>
                <div>
                  <label>E-mail 1*</label>
                  <input
                    type="email"
                    name="email1"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email1}
                    className={
                      formik.touched.email1 && formik.errors.email1
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.email1 && formik.errors.email1 ? (
                    <span>{formik.errors.email1}</span>
                  ) : null}
                </div>
                <div>
                  <label>E-mail 2</label>
                  <input
                    type="email"
                    name="email2"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email2}
                  />
                </div>
              </div>
              <p>
                Por favor informe um e-mail que ainda não foi utilizado em outra
                conta do Pagseguro.
              </p>
            </fieldset>
            <fieldset>
              <p className="title">
                Endereço da <strong>marcenaria</strong>
              </p>
              <div className="endereco1">
                <div>
                  <label>CEP</label>
                  <Input
                    name="cep"
                    type="tel"
                    id="cep"
                    value={endereco.cep}
                    mask="99999-999"
                    maskChar={null}
                    onChange={(e) => {
                      pesquisacep(e.target.value);
                      setErroCep();
                    }}
                    onBlur={formik.handleBlur}
                    className={errocep ? 'redborder' : ''}
                  />
                  {errocep ? <span>{errocep}</span> : null}
                </div>
                <div>
                  <label>Endereço*</label>
                  <input
                    name="rua"
                    type="text"
                    id="rua"
                    value={endereco.rua}
                    onChange={(e) => {
                      setEndereco({
                        cep: endereco.cep,
                        rua: e.target.value,
                        bairro: endereco.bairro,
                        cidade: endereco.cidade,
                        uf: endereco.uf,
                      });
                      setErroRua();
                    }}
                    className={errorua ? 'redborder' : ''}
                  />
                  {errorua ? <span>{errorua}</span> : null}
                </div>
              </div>

              <div className="endereco2">
                <div>
                  <label>Número*</label>
                  <input
                    type="tel"
                    name="numero"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.numero}
                    className={
                      formik.touched.numero && formik.errors.numero
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.numero && formik.errors.numero ? (
                    <span id="numero-span">{formik.errors.numero}</span>
                  ) : null}
                </div>
                <div>
                  <label>Bairro*</label>
                  <input
                    name="bairro"
                    type="text"
                    id="bairro"
                    value={endereco.bairro}
                    onChange={(e) => {
                      setEndereco({
                        cep: endereco.cep,
                        rua: endereco.rua,
                        bairro: e.target.value,
                        cidade: endereco.cidade,
                        uf: endereco.uf,
                      });
                      setErrobairro();
                    }}
                    className={errobairro ? 'redborder' : ''}
                  />
                  {errobairro ? <span>{errobairro}</span> : null}
                </div>
                <div>
                  <label>Cidade*</label>
                  <input
                    name="cidade"
                    type="text"
                    id="cidade"
                    value={endereco.cidade}
                    onChange={(e) => {
                      setEndereco({
                        cep: endereco.cep,
                        rua: endereco.rua,
                        bairro: endereco.bairro,
                        cidade: e.target.value,
                        uf: endereco.uf,
                      });
                      setErroCidade();
                    }}
                    className={errocidade ? 'redborder' : ''}
                  />
                  {errocidade ? <span>{errocidade}</span> : null}
                </div>
                <div>
                  <label>UF*</label>
                  <input
                    name="uf"
                    type="text"
                    id="uf"
                    size="2"
                    value={endereco.uf}
                    onChange={(e) => {
                      setEndereco({
                        cep: endereco.cep,
                        rua: endereco.rua,
                        bairro: endereco.bairro,
                        cidade: endereco.cidade,
                        uf: e.target.value,
                      });
                      setErroUf();
                    }}
                    className={errouf ? 'redborder' : ''}
                  />
                  {errouf ? <span>{errouf}</span> : null}
                </div>
              </div>
            </fieldset>
            <fieldset className="banco">
              <div className="title">
                <p>
                  Dados <strong>bancários</strong>
                </p>
                <div>
                  <div
                    className="whitecircle"
                    onClick={() => setTipodaConta('Não possuo conta bancária')}
                  >
                    <div
                      className={
                        tipodaconta === 'Não possuo conta bancária'
                          ? 'orangecircle'
                          : ''
                      }
                    />
                  </div>
                  <label>Não possuo conta bancária</label>
                </div>
              </div>
              <div className="bancario">
                <div>
                  <label>Banco*</label>
                  <input
                    type="text"
                    name="banco"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.banco}
                    className={
                      formik.touched.banco && formik.errors.banco
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.banco && formik.errors.banco ? (
                    <span>{formik.errors.banco}</span>
                  ) : null}
                </div>
                <div>
                  <label>Agência*</label>
                  <input
                    type="text"
                    name="agencia"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.agencia}
                    className={
                      formik.touched.agencia && formik.errors.agencia
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.agencia && formik.errors.agencia ? (
                    <span>{formik.errors.agencia}</span>
                  ) : null}
                </div>
                <div>
                  <label>Conta*</label>
                  <input
                    type="text"
                    name="conta"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.conta}
                    className={
                      formik.touched.conta && formik.errors.conta
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.conta && formik.errors.conta ? (
                    <span>{formik.errors.conta}</span>
                  ) : null}
                </div>
                <div>
                  <div>
                    <label>Conta Corrente</label>
                    <div
                      className="whitecircle"
                      onClick={() => setTipodaConta('Conta Corrente')}
                    >
                      <div
                        className={
                          tipodaconta === 'Conta Corrente' ? 'orangecircle' : ''
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label>Conta Poupança</label>
                    <div
                      className="whitecircle"
                      onClick={() => setTipodaConta('Conta Poupança')}
                    >
                      <div
                        className={
                          tipodaconta === 'Conta Poupança' ? 'orangecircle' : ''
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p>
                A conta bancária informada deve estar vinculada aos dados de
                cadastro. Ou seja, se o cadastro for realizado em CNPJ a conta
                deve ser jurídica e vinculada ao CNPJ informado. Se o cadastro
                for realizado em CPF, a conta bancária deve estar em nome da
                pessoa física cadastrada.
              </p>
            </fieldset>
            <fieldset className="documentos">
              <p className="title">
                Documentos <strong>necessários</strong>
              </p>
              <div>
                <div>
                  <label> RG/CPF ou CNH do sócio principal*</label>
                  <label
                    className={
                      erroFileRG ? 'redborder anexar-btn' : 'anexar-btn'
                    }
                    htmlFor="fileRG"
                  >
                    <ImAttachment />
                    Anexar arquivos
                  </label>
                  <input
                    hidden
                    type="file"
                    name="file"
                    id="fileRG"
                    onChange={(event) => {
                      setFileRG(event.currentTarget.files[0]);
                      setErroFileRG();
                    }}
                  />
                  {fileRG && (
                    <div className="fileName">
                      <p>{fileRG.name}</p>
                    </div>
                  )}{' '}
                  {erroFileRG ? <span>{erroFileRG}</span> : null}
                </div>
                <div>
                  <label>Comprovante de endereço*</label>
                  <label
                    htmlFor="fileEndereco"
                    className={
                      erroFileEndereco ? 'redborder anexar-btn' : 'anexar-btn'
                    }
                  >
                    <ImAttachment />
                    Anexar arquivos
                  </label>
                  <input
                    hidden
                    type="file"
                    name="file"
                    id="fileEndereco"
                    onChange={(event) => {
                      setFileEndereco(event.currentTarget.files[0]);
                      setErroFileEndereco();
                    }}
                  />
                  {fileEndereco && (
                    <div className="fileName">
                      <p>{fileEndereco.name}</p>
                    </div>
                  )}
                  {erroFileEndereco ? <span>{erroFileEndereco}</span> : null}
                </div>
                <div>
                  <label>Comprovante bancário*</label>
                  <label
                    className={
                      erroFileBancario ? 'redborder anexar-btn' : 'anexar-btn'
                    }
                    htmlFor="fileBancario"
                  >
                    <ImAttachment />
                    Anexar arquivos
                  </label>
                  <input
                    hidden
                    type="file"
                    name="file"
                    id="fileBancario"
                    onChange={(event) => {
                      setFileBancario(event.currentTarget.files[0]);
                      setErroFileBancario();
                    }}
                  />
                  {fileBancario && (
                    <div className="fileName">
                      <p>{fileBancario.name}</p>
                    </div>
                  )}
                  {erroFileBancario ? <span>{erroFileBancario}</span> : null}
                </div>
              </div>
              <p className="campos-obrigatorios">*Campos obrigatórios</p>
            </fieldset>
            <div className="sendbtn">
              <div className={disabledButton ? 'disabledButton' : ''} />
              <button type="submit" onClick={handleSubmit}>
                {formButton}
              </button>
            </div>
          </form>
        </Interesse>

        <div id="bottom-footer">
          <p id="rights">GMAD © 2020 / Todos os direitos reservados</p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://agenciaade.com.br/"
          >
            <p>
              Criado com{' '}
              <img src={require('../../assets/heart.svg')} alt="Coração" /> por
              agenciaade.com.br
            </p>
          </a>
        </div>
      </Container>
      <Modal show={openModal}>
        <div className="modal-mask" onClick={() => setOpenModal(false)} />
        <div className="modal-body">
          <button
            className="modal-close"
            type="button"
            onClick={() => {
              setOpenModal(false);
              setVideoID();
            }}
          >
            {/* <GrFormClose /> */} X
          </button>
          <div className="infos">
            <div className="title">
              <div className="bg title-bg" />
              <div className="content">
                <h2>{!!videoInfo && videoInfo.snippet.title}</h2>
                <p className="descricao">
                  {!!videoInfo && videoInfo.snippet.description}
                </p>
              </div>
            </div>
          </div>
          <div className="video">
            <div className="bg-square" />
            <iframe
              id="player"
              type="text/html"
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${videoID}?autoplay=1&mute=1&rel=0`}
              frameBorder="0"
              title="Modal Video"
            ></iframe>
          </div>
        </div>
      </Modal>
    </>
  );
}
