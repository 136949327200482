import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

import { Whats } from './style';

export default function Whatsapp({ phone }) {
  return (
    <Whats>
      <a
        href={`https://wa.me/${phone ? phone : '554284094524'}?text=Olá, gostaria de saber mais sobre os meios de pagamento da GMAD.`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp size={40} />
      </a>
    </Whats>
  );
}
